import React from "react";
import { Switch, Route } from "react-router-dom";
import PaymentRequestLink from "../chatapp/views/OrderDetailing/PaymentRequestLink";
import {
  DefaultLayout,
  CreateCustomOrders,
  CustomOrderDetails,
  CustomOrderList,
  DashBoard,
  ProjectsList,
  ProjectDetails,
  ClientsList,
  ClientDetails,
  RaisePayment,
  WalletLedger,
  MasterProjectsList,
  SelectProject,
  CreateNewProject,
  Client,
  TrelloList,
  TrelloDetails,
  PartnersList,
  PartnerDetails
} from "../Layouts";
import PartnerCommissionList from "../Layouts/Partners/PartnerDetails/PartnerCommissionList";
import PartnerCommissionWithdrawalList from "../Layouts/Partners/PartnerDetails/PartnerCommissionWithdrawalList";
import { TransectionsList } from "../Layouts/Transaction/TransactionsList";
import { ProjectCatlogList } from "../Layouts/ProjectCatlog";
import { GeneratePaymentRequest } from "../Layouts/Payments";
import { LocalProjectCatlogList } from "../Layouts/ProjectCatlogLocal";
import { AttachmentCategoryList } from "../Layouts/AttachmentCategory";
const orderList = React.lazy(() =>
  import("../chatapp/views/OrderDetailing/index")
);
const orderDetailing = React.lazy(() =>
  import("../chatapp/views/OrderDetailing/orderDetailing")
);
const CreateDraftOrder = React.lazy(() =>
  import("../chatapp/views/DraftOrder/index")
);
const ListDraftOrder = React.lazy(() =>
  import("../chatapp/views/DraftOrder/listDarftOrders")
);
const DraftOrderDetails = React.lazy(() =>
  import("../chatapp/views/DraftOrder/draftOrderDetails")
);
const ResumeDraftOrder = React.lazy(() =>
  import("../chatapp/views/DraftOrder/resumeDraftOrder")
);
const ReviewDraftOrder = React.lazy(() =>
  import("../chatapp/views/DraftOrder/ReviewOrder")
);
const GenerateDraftOrder = React.lazy(() =>
  import("../chatapp/views/DraftOrder/GenerateOrder")
);
const ChildDraftOrder = React.lazy(() =>
  import("../chatapp/views/ChildDraftOrder/index")
);
const AddPaymentRequest = React.lazy(() =>
  import("../chatapp/views/OrderDetailing/AddPaymentRequest")
);
// const GeneratePaymentRequest = React.lazy(() =>
//   import("../chatapp/views/OrderDetailing/generatePaymentRequest")
// );
const ListPaymentRequest = React.lazy(() =>
  import("../chatapp/views/OrderDetailing/PaymentRequestList")
);
const CreatePromo = React.lazy(() =>
  import("../chatapp/views/Discounts/CreatePromo")
);
const PromoList = React.lazy(() => import("../chatapp/views/Discounts/Promo"));
const AddTasks = React.lazy(() => import("../chatapp/views/Tasks/add"));
const Tasks = React.lazy(() => import("../chatapp/views/Tasks/index"));
const TasksDetails = React.lazy(() => import("../chatapp/views/Tasks/details"));
const BulkSms = React.lazy(() => import("../chatapp/views/BulkSms/index"));
const TransactionList = React.lazy(() =>
  import("../chatapp/views/Billings/index")
);
const TrelloDetail = React.lazy(() =>
  import("../chatapp/views/OrderDetailing/Trellodetails")
);

const AdminRoutes = (props) => {
  return (
    <DefaultLayout>
      <Switch>
        <Route path="/home/ap/dashboard" exact={true} component={DashBoard} />
        <Route
          path="/home/ap/projects/master"
          exact={true}
          component={MasterProjectsList}
        />
          <Route
          path="/home/ap/catalog/list"
          exact={true}
          component={ProjectCatlogList}
        />
         <Route
          path="/home/ap/local-catalog/list"
          exact={true}
          component={LocalProjectCatlogList}
        />
        <Route
          path="/home/ap/projects/list"
          exact={true}
          component={ProjectsList}
        />
        <Route
          path="/home/ap/project/details/:id/:customerId/:prjCode"
          exact={true}
          component={ProjectDetails}
        />
        <Route path="/home/ap/orders/list" exact={true} component={orderList} />
        <Route
          path={[
            "/home/ap/order/details/:orderId",
            "/home/ap/order/details/:orderId/apply/wallet/:id",
          ]}
          exact={true}
          component={orderDetailing}
        />
        <Route
          path={[
            "/home/ap/order/child/details/:orderId",
            "/home/ap/order/child/details/:orderId/apply/wallet/:id",
          ]}
          exact={true}
          component={orderDetailing}
        />
        <Route
          path={[
            "/home/ap/draft/order/create",
            "/home/ap/draft/order/create/:id",
          ]}
          exact={true}
          component={CreateDraftOrder}
        />
        <Route
          path="/home/ap/draft/orders/list"
          exact={true}
          component={ListDraftOrder}
        />
        <Route
          path="/home/ap/draft/order/details/:orderId"
          exact={true}
          component={DraftOrderDetails}
        />
        <Route
          path="/home/ap/draft/order/resume"
          exact={true}
          component={ResumeDraftOrder}
        />
        <Route
          path="/home/ap/draft/order/review"
          exact={true}
          component={ReviewDraftOrder}
        />
        <Route
          path="/home/ap/draft/order/generate"
          exact={true}
          component={GenerateDraftOrder}
        />
        <Route
          path="/home/ap/child/draft/order/create"
          exact={true}
          component={ChildDraftOrder}
        />
        <Route
          path={[
            "/home/ap/custom/order/create",
            "/home/ap/custom/order/create/:customerId/:projectId",
            "/home/ap/custom/order/create/:customerId/:projectId/:category",
            "/home/ap/custom/order/create/:customerId/:projectId/:category/:type",

          ]}
          exact={true}
          component={CreateCustomOrders}
        />
          <Route
          path="/home/ap/attachement-category/list"
          exact={true}
          component={AttachmentCategoryList}
        />
        <Route
          path="/home/ap/custom/orders/list"
          exact={true}
          component={CustomOrderList}
        />
        <Route
          path="/home/ap/custom/orders/details/:id"
          exact={true}
          component={CustomOrderDetails}
        />
         <Route
          path="/home/ap/partner/commission-list/:id"
          exact={true}
          component={PartnerCommissionList}
        />
            <Route
          path="/home/ap/partner/commission-withdrawal-list/:id"
          exact={true}
          component={PartnerCommissionWithdrawalList}
        />
        <Route
          path="/home/ap/clients/list"
          exact={true}
          component={ClientsList}
        />
        <Route
          path={[
            "/home/ap/client/details/:client_id",
            "/home/ap/client/details/:client_id/edit",
          ]}
          exact={true}
          component={Client}
        />
        <Route path="/home/ap/partners/list" exact={true} component={PartnersList}/>
        <Route path="/home/ap/partners/details/:id" exact={true} component={PartnerDetails}/>
        <Route
          path="/home/ap/payment/request/generate"
          exact={true}
          component={GeneratePaymentRequest}
        />
        <Route
          path="/home/ap/payment/requests/list"
          exact={true}
          component={ListPaymentRequest}
        />
          <Route
          path="/home/ap/payment/requests/link/list"
          exact={true}
          component={PaymentRequestLink}
        />
        <Route
          path={["/home/ap/payment/add/:type/:country/:customerId/:id"]}
          exact={true}
          component={RaisePayment}
        />
        <Route
          path="home/ap/order/details/payment/add"
          exact={true}
          component={AddPaymentRequest}
        />
        <Route
          path="/home/ap/promo/create"
          exact={true}
          component={CreatePromo}
        />
        <Route
          path="/home/ap/project/select/:view"
          exact={true}
          component={SelectProject}
        />
        <Route
          path={["/home/ap/project/create", "/home/ap/project/create/for/:f"]}
          exact={true}
          component={CreateNewProject}
        />
        <Route path="/home/ap/promos/list" exact={true} component={PromoList} />
        <Route path="/home/ap/task/add" exact={true} component={AddTasks} />
        <Route path="/home/ap/tasks/list" exact={true} component={Tasks} />
        <Route
          path="/home/ap/task/details"
          exact={true}
          component={TasksDetails}
        />
        <Route path="/home/ap/sms/send" exact={true} component={BulkSms} />
        <Route
          path="/home/ap/transactions"
          exact={true}
          component={TransactionList}
        />
         <Route
          path="/home/ap/transaction-list"
          exact={true}
          component={TransectionsList}
        />
        {/* <Route path="/home/ap/trello/detail-list" exact={true} component={TrelloDetails}/> */}
        <Route
          path="/home/ap/trello/details/:id"
          exact={true}
          component={TrelloDetails}
        />

        <Route
          path="/home/ap/trello/details"
          exact={true}
          component={TrelloDetail}
        />
        <Route
          path="/home/ap/trello/list"
          exact={true}
          component={TrelloList}
        />
        <Route
          path="/home/ap/client/wallent/ledger/:id"
          exact={true}
          component={WalletLedger}
        />
        {/* <Route path={'/home/ap/client/details/:client_id/:table'} exact={true} component={}/> */}
      </Switch>
    </DefaultLayout>
  );
};

export default AdminRoutes;
