import { combineReducers } from "redux";
import customersReducer from "./CustomersReducer";
import customerReducer from "./CustomerReducer";
import projectsReducer from "./ProjectsReducer";
import adminsReducer from "./AdminsReducer";
import projectsListReducer from "./ProjectsListReducer";
import projectReducer from "./ProjectReducer";
import ordersReducer from "./OrdersReducer";
import productsReducer from "./ProductsReducer";
import orderSummaryReducer from "./OrderSummaryReducer";
import customerWalletReducer from "./CustomerWalletReducer";
import currencyReducer from "./CurrencyReducer";
import countryReducer from "./CountryReducer";
import statesReducer from "./StatesReducer";
import phoneCountryInputReducer from "./PhoneCountryInputReducer";
import walletLedgerReducer from "./WalletLedgerReducer";
import masterProjectsReducer from "./MasterProjectsReducer";
import products2Reducer from "./Products2Reducer";
import customOrdersReducer from "./CustomOrdersReducer";
import customOrderReducer from "./CustomOrderReducer";
import requestFormsReducer from "./RequestFormsReducer";
import trelloBoardsReducer from "./TrelloBoardsReducer";
import trelloCardsReducer from "./TrelloCardsReducer";
import trelloCardInfoReducer from "./TrelloCardInfoReducer";
import trelloMembersList from "./TrelloMembersListReducer";
import trelloList from "./TrelloListReducer";
import trelloAttachmentList from "./TrelloAttachmentListReducer";
import trelloCommentList from "./TrelloCommentListReducer";
import trelloCheckListReducer from "./TrelloCheckListReducer";
import trelloNewList from "./TrelloNewListReducer";

import trelloAllMembersList from './TrelloAllMembersListReducer';
import trelloSearchCardList from './TrelloSearchCardListReducer';
import projectFolderpath from './ProjectFolderpathReducer';
import partnersList from './PartnersListReducer';
import partnerDetailsReducer from './PartnerDetailsReducer';
import partnerDetailsSingleReducer from "./PartnerDetailsSingleReducer";
import partnerLocations from './PartnerLocationsReducer';
import unitsReducer from "./UnitsReducer";
import partnersComissionList from './PartnersComissionListReducer';
import partnersComissionWithdrawalList from './PartnersComissionWithdrawalListReducer'
import projectsCatlogReducer from './ProjectsCatlogReducer';
import paymentLinkListReducer from './paymentLinkListReducer';
import transectionList from './transectionListReducer';
import gatewayList from './GatewayListReducer';
import downlineAdmins from './downlineAdmins';
import partnerleadlist from './PartnerleadlistReducer';
import projectAttachementCategories from './ProjectAttachementCategoriesReducer';
import projectAttachementSubCategories from './ProjectAttachementSubCategoriesReducer';
import browserFileListReducer from "./BrowserFileListReducer";
import BrowserFileActionLogsReducer from "./BrowserFileActionLogsReducer";
import SitesListReducer from "./SitesListReducer";
import ProjectDetailReducer from "./ProjectDetailReducer";





const appreducers = combineReducers({
  units: unitsReducer,
  customers: customersReducer,
  customer: customerReducer,
  projects: projectsReducer,
  admins: adminsReducer,
  downlineAdmins: downlineAdmins,
  projectsList: projectsListReducer,
  project: projectReducer,
  orders: ordersReducer,
  products: productsReducer,
  orderSummary: orderSummaryReducer,
  customerWallet: customerWalletReducer,
  currencies: currencyReducer,
  countries: countryReducer,
  states: statesReducer,
  phoneCountryInput: phoneCountryInputReducer,
  walletLedger: walletLedgerReducer,
  masterProjects: masterProjectsReducer,
  products2: products2Reducer,
  customOrders: customOrdersReducer,
  customOrder: customOrderReducer,
  requestForms: requestFormsReducer,
  trelloBoards: trelloBoardsReducer,
  trelloCards: trelloCardsReducer,
  trelloCardInfo: trelloCardInfoReducer,
  trelloMembersList: trelloMembersList,
  trelloList: trelloList,
  trelloAttachmentList: trelloAttachmentList,
  trelloCommentList: trelloCommentList,
  trelloCheckList: trelloCheckListReducer,
  trelloNewList: trelloNewList,
  trelloAllMembersList: trelloAllMembersList,
  trelloSearchCardList: trelloSearchCardList,
  projectFolderpath: projectFolderpath,
  partnersList: partnersList,
  partnerDetails: partnerDetailsReducer,
  partnerDetailsSingle: partnerDetailsSingleReducer,
  partnerLocations: partnerLocations,
  partnersComissionList: partnersComissionList,
  partnersComissionWithdrawalList: partnersComissionWithdrawalList,
  projectsCatlog: projectsCatlogReducer,
  paymentLinkList: paymentLinkListReducer,
  transectionList: transectionList,
  gatewayList: gatewayList,
  partnerleadlist: partnerleadlist,
  projectAttachementCategories: projectAttachementCategories,
  projectAttachementSubCategories: projectAttachementSubCategories,
  browserFileList: browserFileListReducer,
  browserFileActionLogsList: BrowserFileActionLogsReducer,
  sitesList: SitesListReducer,
  projectDetail: ProjectDetailReducer,
});

// export default appreducers;

const reducers = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined;
  }

  return appreducers(state, action);
};

export default reducers;